import { stripTags } from '@zicht/nuxt-util'
import { getMediaSeoImage } from '~/assets/media'

function getHomeUrl(page) {
  const match = page?.url?.match(/^(https?):\/\/([^/]+)/i)
  return match ? `${match[1]}://${match[2]}/` : undefined
}

const stripTrailingSlashFromEnHomeUrl = (url) => {
  if (typeof url !== 'string' || !url.includes('/en/')) {
    return url
  }
  const urlParts = new URL(url)
  if (urlParts.pathname !== '/en/') {
    return url
  }
  urlParts.pathname = '/en'
  return urlParts.href
}

export const seoHead = (page) => {
  const pageImage = getMediaSeoImage(page?.image || undefined)
  const pageDescription = stripTags(page?.introduction || page?.description || page?.dynamicContent?.filter(({ type }) => type === 'text').map(({ text }) => text)?.[0] || '')

  const meta = [
    { name: 'description', content: page?.seo?.description || pageDescription },
    { name: 'keywords', content: page?.seo?.keywords },

    // Robots
    // https://developers.google.com/search/docs/advanced/robots/robots_meta_tag
    { name: 'robots', content: page?.seo?.advanced.robots },
    { name: 'robots', content: page?.expiryDate ? `unavailable_after: ${page.expiryDate}` : undefined },

    // Twitter
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: page?.seo?.seoGlobal?.twitterHandle },
    { name: 'twitter:url', content: page?.url },
    { name: 'twitter:title', content: page?.seo?.social.twitter.title },
    { name: 'twitter:description', content: page?.seo?.social.twitter.description || pageDescription },
    { name: 'twitter:image', content: page?.seo?.social.twitter.image?.url || pageImage?.url },

    // Facebook
    { property: 'fb:app_id', content: page?.seo?.seoGlobal?.facebookHandle },
    { property: 'og:url', content: page?.url },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: page?.seo?.social.facebook.title },
    { property: 'og:image', content: page?.seo?.social.facebook.image?.url || pageImage?.url },
    { property: 'og:image:width', content: page?.seo?.social.facebook.image?.width || pageImage?.width },
    { property: 'og:image:height', content: page?.seo?.social.facebook.image?.height || pageImage?.height },
    { property: 'og:description', content: page?.seo?.social.facebook.description || pageDescription },
    { property: 'og:site_name', content: page?.seo?.seoGlobal?.siteName },
    { property: 'og:locale', content: page?.language },
  ]

  let canonicalHref = page?.seo?.advanced.canonical || page?.url
  let canonicalLanguage
  if (page?.section === 'publicationPage' && page?.language === 'nl' && page?.publicationLanguage === 'English') {
    const enLanguageAlternative = page?.alternativeLanguages?.filter(({ language }) => language === 'en')
    if (enLanguageAlternative[0] !== undefined) {
      canonicalHref = enLanguageAlternative[0].url
      canonicalLanguage = enLanguageAlternative[0].language
    }
  }
  const link = [
    { rel: 'home', href: getHomeUrl(page) },
    { rel: 'canonical', href: stripTrailingSlashFromEnHomeUrl(canonicalHref), ...(canonicalLanguage && { hreflang: canonicalLanguage }) },
    {
      rel: 'alternate',
      href: stripTrailingSlashFromEnHomeUrl(page?.alternativeLanguages?.filter(({ language }) => language === 'en').map(({ url }) => url)?.[0]),
      hreflang: 'x-default',
    },
    ...page?.alternativeLanguages?.map(({ url, language }) => ({
      rel: 'alternate',
      href: stripTrailingSlashFromEnHomeUrl(url === page?.url ? page?.seo?.advanced.canonical || page?.url : url),
      hreflang: language,
    })),
  ]

  // https://github.com/ethercreative/seo/blob/v3/src/templates/_seo/meta.twig
  return {
    htmlAttrs: { lang: page?.language || 'nl' },
    link: link.filter(({ href }) => href),
    meta: meta.filter(({ content }) => content),
    title: page?.seo?.title,
  }
}
